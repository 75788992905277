<template>
	<ion-content :fullscreen="true">
		<div class="title-container">
			<ion-row class="title-content">
				<ion-col class="title-text-only" size="9">
					<h1>Your Health Declaration</h1>
					<p class="title-content-notice">View your previously declared record here.</p>
				</ion-col>
			</ion-row>
		</div>
		<div class="content-container" v-if="isPageLoading === false">
			<ion-card class="content-container-card" v-if="hasSymptoms === true" color="danger">
				<ion-card-content>
					<ion-row class="content-item-container">
						<ion-col>
							<div start="start">
								<div class="message-title-container">
									<ion-icon class="message-icon" name="sad-sharp" size="large" color="light"></ion-icon>
									<h1 class="message-text">You are not feeling well on this day.</h1>
								</div>
							</div>
						</ion-col>
					</ion-row>
					<!-- <ion-row>
						<div class="message-subtitle-container">
							<p>Please seek immediate medical attention if symptoms persist.</p>
						</div>
					</ion-row> -->
				</ion-card-content>
			</ion-card>
			<ion-card class="content-container-card" v-if="hasSymptoms === false" color="success">
				<ion-card-content>
					<ion-row class="content-item-container">
						<ion-col>
							<div start="start">
								<div class="message-title-container">
									<ion-icon class="message-icon" name="happy-sharp" size="large" color="light"></ion-icon>
									<h1 class="message-text">You are feeling well on this day</h1>
								</div>
							</div>
						</ion-col>
					</ion-row>
					<!-- <ion-row>
						<div class="message-subtitle-container">
							<p>We're glad that you are now okay. Please stay safe!</p>
						</div>
					</ion-row> -->
				</ion-card-content>
				<ion-button slot="right">Change</ion-button>
			</ion-card>
			<ion-card class="content-container-card" v-if="hasSymptoms === true || hasSymptoms === false">
				<ion-card-header class="content-card-title">
					<ion-card-subtitle>Date:</ion-card-subtitle>
				</ion-card-header>
				<ion-card-content>
					<ion-card-title>
						<ion-item class="input-item-custom">
							<ion-label position="floating">MM/DD/YYYY</ion-label>
							<ion-datetime 
								:readonly="isViewMode"
								:value="filledDate"
								@ionChange="filledDate = $event.target.value;"
								display-format="MM/DD/YYYY"
								:min="minDate"
								:max="maxDate">
							</ion-datetime>
						</ion-item>
					</ion-card-title>
				</ion-card-content>
			</ion-card>
			<ion-card class="content-container-card" v-if="hasSymptoms === true">
				<ion-card-header>
					<ion-card-subtitle class="content-title">What are the symptoms you're experiencing right now?</ion-card-subtitle>
					<ion-card-subtitle class="content-subtitle"><i>Anu-ano po ang mga sintomas na iyong nararanasan?</i></ion-card-subtitle>
					<ion-card-title>
						<ion-list>
							<ion-item class="input-item-custom">
								<ion-label>Sore throat (Pananakit ng lalamunan / masakit lumunok)</ion-label>
								<ion-icon v-if="isViewMode" end="end" :name="hasSoreThroat ? 'checkbox' : 'square-outline'"></ion-icon>
							</ion-item>
							<ion-item class="input-item-custom">
								<ion-label>Body pains (Pananakit ng katawan)</ion-label>
								<ion-icon v-if="isViewMode" end="end" :name="hasBodyPains ? 'checkbox' : 'square-outline'"></ion-icon>
							</ion-item>
							<ion-item class="input-item-custom">
								<ion-label>Headache (Pananakit ng ulo)</ion-label>
								<ion-icon v-if="isViewMode" end="end" :name="hasHeadache ? 'checkbox' : 'square-outline'"></ion-icon>
							</ion-item>
							<ion-item class="input-item-custom">
								<ion-label>Fever (Lagnat)</ion-label>
								<ion-icon v-if="isViewMode" end="end" :name="hasFever ? 'checkbox' : 'square-outline'"></ion-icon>
							</ion-item>
							<ion-item class="input-item-custom">
								<ion-label>Loss of taste or smell (Nawalan ng panglasa o pangamoy)</ion-label>
								<ion-icon v-if="isViewMode" end="end" :name="hasLossTasteSmell ? 'checkbox' : 'square-outline'"></ion-icon>
							</ion-item>
							<ion-item class="input-item-custom">
								<ion-label>Diarrhea (pagtatae)</ion-label>
								<ion-icon v-if="isViewMode" end="end" :name="hasDiarrhea ? 'checkbox' : 'square-outline'"></ion-icon>
							</ion-item>
							<ion-item class="input-item-custom">
								<ion-label>Chest Pains (pananakit ng dibdib)</ion-label>
								<ion-icon v-if="isViewMode" end="end" :name="hasChestPains ? 'checkbox' : 'square-outline'"></ion-icon>
							</ion-item>
							<ion-item class="input-item-custom">
								<ion-label>Conjunctivitis (pamumula ng mata)</ion-label>
								<ion-icon v-if="isViewMode" end="end" :name="hasConjunctivitis ? 'checkbox' : 'square-outline'"></ion-icon>
							</ion-item>
						</ion-list>
					</ion-card-title>
				</ion-card-header>
			</ion-card>
			<ion-card class="content-container-card" v-if="hasSymptoms === false || hasSymptoms === true || hasSoreThroat || hasBodyPains || hasHeadache || hasFever || hasLossTasteSmell || hasDiarrhea || hasChestPains || hasConjunctivitis">
				<ion-card-header>
					<ion-card-subtitle class="content-title">Is there a confirmed case from your barangay/street?</ion-card-subtitle>
					<ion-card-subtitle class="content-subtitle"><i>May kumpirmadong kaso na ba ng COVID-19 sa inyong brgy/area of residence?</i></ion-card-subtitle>
					<ion-card-title>
						<ion-list>
							<ion-radio-group 
								class="radio-container"
								:value="hasConfirmedCase"
								@ionChange="hasConfirmedCase = $event.target.value;"
							>
								<ion-item class="input-item-custom" lines="none">
									<ion-icon v-if="isViewMode" class="radio-icon" :name="hasConfirmedCase ? 'radio-button-on-sharp' : 'radio-button-off-sharp'"></ion-icon>
									<ion-label>Yes</ion-label>
								</ion-item>
								<ion-item class="input-item-custom" lines="none">
									<ion-icon v-if="isViewMode" class="radio-icon" :name="hasConfirmedCase ? 'radio-button-off-sharp' : 'radio-button-on-sharp'"></ion-icon>
									<ion-label>No</ion-label>
								</ion-item>
							</ion-radio-group>
						</ion-list>
					</ion-card-title>
				</ion-card-header>
			</ion-card>
			<ion-card class="content-container-card" v-if="hasConfirmedCase !== '' && (hasSymptoms === false || hasSymptoms === true || hasSoreThroat || hasBodyPains || hasHeadache || hasFever || hasLossTasteSmell || hasDiarrhea || hasChestPains || hasConjunctivitis)">
				<ion-card-header>
					<ion-card-subtitle class="content-title">Have you worked together or stayed in the same close environment of a confirmed COVID-19 case?</ion-card-subtitle>
					<ion-card-subtitle class="content-subtitle"><i>May nakasama ka ba o nakatrabahong tao na kumpirmadong may COVID-19 / may impeksyon ng coronavirus?</i></ion-card-subtitle>
					<ion-card-title>
						<ion-list>
							<ion-radio-group 
								class="radio-container"
								:value="hasWorkedWithConfirmedCase"
								@ionChange="hasWorkedWithConfirmedCase = $event.target.value;"
							>
								<ion-item class="input-item-custom" lines="none">
									<ion-icon v-if="isViewMode" class="radio-icon" :name="hasWorkedWithConfirmedCase ? 'radio-button-on-sharp' : 'radio-button-off-sharp'"></ion-icon>
									<ion-label>Yes</ion-label>
								</ion-item>
								<ion-item class="input-item-custom" lines="none">
									<ion-icon v-if="isViewMode" class="radio-icon" :name="hasWorkedWithConfirmedCase ? 'radio-button-off-sharp' : 'radio-button-on-sharp'"></ion-icon>
									<ion-label>No</ion-label>
								</ion-item>
							</ion-radio-group>
						</ion-list>
					</ion-card-title>
				</ion-card-header>
			</ion-card>
			<ion-card class="content-container-card" v-if="hasWorkedWithConfirmedCase !== '' && (hasSymptoms === false || hasSymptoms === true || hasConfirmedCase !== '' || hasSoreThroat || hasBodyPains || hasHeadache || hasFever || hasLossTasteSmell || hasDiarrhea || hasChestPains || hasConjunctivitis)"> 
				<ion-card-header>
					<ion-card-subtitle class="content-title">Have you had any contact with anyone with fever, cough, colds and sore throat in the past 2 weeks?</ion-card-subtitle>
					<ion-card-subtitle class="content-subtitle"><i>Mayroon ka bang nakasama na may lagnat, ubo, sipon o sakit ng lalamunan sa nakalipas ng dalawang (2) linggo?</i></ion-card-subtitle>
					<ion-card-title>
						<ion-list>
							<ion-radio-group 
							class="radio-container"
							:value="hasContactWithSymptoms"
							@ionChange="hasContactWithSymptoms = $event.target.value;"
							>
								<ion-item class="input-item-custom" lines="none">
									<ion-icon v-if="isViewMode" class="radio-icon" :name="hasContactWithSymptoms ? 'radio-button-on-sharp' : 'radio-button-off-sharp'"></ion-icon>
									<ion-label>Yes</ion-label>
								</ion-item>
								<ion-item class="input-item-custom" lines="none">
									<ion-icon v-if="isViewMode" class="radio-icon" :name="hasContactWithSymptoms ? 'radio-button-off-sharp' : 'radio-button-on-sharp'"></ion-icon>
									<ion-label>No</ion-label>
								</ion-item>
							</ion-radio-group>
						</ion-list>
					</ion-card-title>
				</ion-card-header>
			</ion-card>
			<ion-card class="content-container-card" v-if="hasContactWithSymptoms !== '' && (hasSymptoms === false || hasSymptoms === true || hasWorkedWithConfirmedCase !== '' || hasConfirmedCase !== '' || hasSoreThroat || hasBodyPains || hasHeadache || hasFever || hasLossTasteSmell || hasDiarrhea || hasChestPains || hasConjunctivitis)">
				<ion-card-header>
					<ion-card-subtitle class="content-title">Have you travelled outside of the Philippines in the last 14 days?</ion-card-subtitle>
					<ion-card-subtitle class="content-subtitle"><i>Ikaw ba ay nagbyahe sa labas ng Pilipinas sa nakalipas na 14 na araw?</i></ion-card-subtitle>
					<ion-card-title>
						<ion-list>
							<ion-radio-group 
								class="radio-container"
								:value="hasTravelledOutsidePH"
								@ionChange="hasTravelledOutsidePH = $event.target.value;"
							>
								<ion-item class="input-item-custom" lines="none">
									<ion-icon v-if="isViewMode" class="radio-icon" :name="hasTravelledOutsidePH ? 'radio-button-on-sharp' : 'radio-button-off-sharp'"></ion-icon>
									<ion-label>Yes</ion-label>
								</ion-item>
								<ion-item class="input-item-custom" lines="none">
									<ion-icon v-if="isViewMode" class="radio-icon" :name="hasTravelledOutsidePH ? 'radio-button-off-sharp' : 'radio-button-on-sharp'"></ion-icon>
									<ion-label>No</ion-label>
								</ion-item>
							</ion-radio-group>
						</ion-list>
					</ion-card-title>
				</ion-card-header>
			</ion-card>
			<ion-card class="content-container-card" v-if="hasTravelledOutsidePH !== '' && (hasSymptoms === false || hasSymptoms === true || hasContactWithSymptoms !== '' || hasWorkedWithConfirmedCase !== '' || hasConfirmedCase !== '' || hasSoreThroat || hasBodyPains || hasHeadache || hasFever || hasLossTasteSmell || hasDiarrhea || hasChestPains || hasConjunctivitis)">
				<ion-card-header>
					<ion-card-subtitle class="content-title">Have you travelled to any area aside from your home?</ion-card-subtitle>
					<ion-card-subtitle class="content-subtitle"><i>Ikaw ba ay nagpunta sa ibang lugar bukod sa iyong bahay?</i></ion-card-subtitle>
					<ion-card-title>
						<ion-list>
							<ion-radio-group 
								class="radio-container"
								:value="hasTravelledOutsideHome"
								@ionChange="hasTravelledOutsideHome = $event.target.value;"
							>
								<ion-item class="input-item-custom" lines="none">
									<ion-icon v-if="isViewMode" class="radio-icon" :name="hasTravelledOutsidePH ? 'radio-button-on-sharp' : 'radio-button-off-sharp'"></ion-icon>
									<ion-label>Yes</ion-label>
								</ion-item>
								<ion-item class="input-item-custom" lines="none">
									<ion-icon v-if="isViewMode" class="radio-icon" :name="hasTravelledOutsidePH ? 'radio-button-off-sharp' : 'radio-button-on-sharp'"></ion-icon>
									<ion-label>No</ion-label>
								</ion-item>
							</ion-radio-group>
						</ion-list>
					</ion-card-title>
				</ion-card-header>
			</ion-card>
			<ion-card class="content-container-card" v-if="hasTravelledOutsideHome === true  && (hasSymptoms === false || hasSymptoms === true || hasContactWithSymptoms !== '' || hasWorkedWithConfirmedCase !== '' || hasConfirmedCase !== '' || hasSoreThroat || hasBodyPains || hasHeadache || hasFever || hasLossTasteSmell || hasDiarrhea || hasChestPains || hasConjunctivitis)">
				<ion-card-header>
					<ion-card-subtitle class="content-title">What places did you visit?</ion-card-subtitle>
					<ion-card-subtitle class="content-subtitle"><i>Saang lugar ka nagpunta?</i></ion-card-subtitle>
					<ion-card-title>
						<ion-input 
						class="mainform-input"
						:readonly="isViewMode"
						:value="placeVisited"
						@ionInput="placeVisited = $event.target.value;"
						placeholder="Insert Place here"></ion-input>
					</ion-card-title>
				</ion-card-header>
			</ion-card>
		</div>
		<div class="content-container" v-if="isPageLoading === true">
			<ion-card class="content-container-card">
				<ion-card-content>
					<ion-row>
						<ion-col>
							<div start="start">
								<div class="message-title-container">
									<ion-skeleton-text class="message-icon" animated style="width: 30px; height: 30px; border-radius: 50%;"></ion-skeleton-text>
									<ion-skeleton-text class="message-text" animated style="width: 80%;"></ion-skeleton-text>
								</div>
							</div>
						</ion-col>
						<ion-col class="card-action-button">
							<ion-skeleton-text end="end" animated style="width: 30%; height: 100%; float: right;"></ion-skeleton-text>
						</ion-col>
					</ion-row>
					<ion-row>
						<ion-skeleton-text class="message-text message-subtitle-container" animated style="width: 60%;"></ion-skeleton-text>
					</ion-row>
				</ion-card-content>
			</ion-card>
			<ion-card class="content-container-card">
				<ion-card-header class="content-card-title">
					<ion-skeleton-text class="message-text" animated style="width: 30%;"></ion-skeleton-text>
				</ion-card-header>
				<ion-card-content>
					<ion-skeleton-text class="input-item-custom message-text" animated style="width: 60%; height: 30px"></ion-skeleton-text>
				</ion-card-content>
			</ion-card>
			<ion-card class="content-container-card">
				<ion-card-header>
					<ion-skeleton-text class="message-text" animated style="width: 80%; height: 20px;"></ion-skeleton-text>
					<ion-skeleton-text class="message-text" animated style="width: 60%; margin-top: 10px;"></ion-skeleton-text>
					<ion-card-title>
						<ion-list>
							<ion-item class="input-item-custom">
								<ion-label>
									<ion-skeleton-text class="message-text" animated style="width: 80%;"></ion-skeleton-text>
								</ion-label>
								<ion-skeleton-text end="end" class="message-text" animated style="width: 15px; height: 15px;"></ion-skeleton-text>
							</ion-item>
							<ion-item class="input-item-custom">
								<ion-label>
									<ion-skeleton-text class="message-text" animated style="width: 80%;"></ion-skeleton-text>
								</ion-label>
								<ion-skeleton-text end="end" class="message-text" animated style="width: 15px; height: 15px;"></ion-skeleton-text>
							</ion-item>
							<ion-item class="input-item-custom">
								<ion-label>
									<ion-skeleton-text class="message-text" animated style="width: 80%;"></ion-skeleton-text>
								</ion-label>
								<ion-skeleton-text end="end" class="message-text" animated style="width: 15px; height: 15px;"></ion-skeleton-text>
							</ion-item>
							<ion-item class="input-item-custom">
								<ion-label>
									<ion-skeleton-text class="message-text" animated style="width: 80%;"></ion-skeleton-text>
								</ion-label>
								<ion-skeleton-text end="end" class="message-text" animated style="width: 15px; height: 15px;"></ion-skeleton-text>

							</ion-item>
							<ion-item class="input-item-custom">
								<ion-label>
									<ion-skeleton-text class="message-text" animated style="width: 80%;"></ion-skeleton-text>
								</ion-label>
								<ion-skeleton-text end="end" class="message-text" animated style="width: 15px; height: 15px;"></ion-skeleton-text>
							</ion-item>
							<ion-item class="input-item-custom">
								<ion-label>
									<ion-skeleton-text class="message-text" animated style="width: 80%;"></ion-skeleton-text>
								</ion-label>
								<ion-skeleton-text end="end" class="message-text" animated style="width: 15px; height: 15px;"></ion-skeleton-text>
							</ion-item>
							<ion-item class="input-item-custom">
								<ion-label>
									<ion-skeleton-text class="message-text" animated style="width: 80%;"></ion-skeleton-text>
								</ion-label>
								<ion-skeleton-text end="end" class="message-text" animated style="width: 15px; height: 15px;"></ion-skeleton-text>
							</ion-item>
							<ion-item class="input-item-custom">
								<ion-label>
									<ion-skeleton-text class="message-text" animated style="width: 80%;"></ion-skeleton-text>
								</ion-label>
								<ion-skeleton-text end="end" class="message-text" animated style="width: 15px; height: 15px;"></ion-skeleton-text>
							</ion-item>
						</ion-list>
					</ion-card-title>
				</ion-card-header>
			</ion-card>
			<ion-card class="content-container-card">
				<ion-card-header>
					<ion-skeleton-text class="message-text" animated style="width: 80%; height: 20px;"></ion-skeleton-text>
					<ion-skeleton-text class="message-text" animated style="width: 60%; margin-top: 10px;"></ion-skeleton-text>
					<ion-card-title>
						<ion-list class="radio-container">
							<ion-item class="input-item-custom" lines="none">
								<ion-skeleton-text class="message-text" animated style="width: 20px; height: 20px; border-radius: 50%"></ion-skeleton-text>
								<ion-skeleton-text class="message-text" animated style="width: 50px; height: 20px;"></ion-skeleton-text>
							</ion-item>
							<ion-item class="input-item-custom" lines="none">
								<ion-skeleton-text class="message-text" animated style="width: 20px; height: 20px; border-radius: 50%"></ion-skeleton-text>
								<ion-skeleton-text class="message-text" animated style="width: 50px; height: 20px;"></ion-skeleton-text>
							</ion-item>
						</ion-list>
					</ion-card-title>
				</ion-card-header>
			</ion-card>
			<ion-card class="content-container-card">
				<ion-card-header>
					<ion-skeleton-text class="message-text" animated style="width: 80%; height: 20px;"></ion-skeleton-text>
					<ion-skeleton-text class="message-text" animated style="width: 60%; margin-top: 10px;"></ion-skeleton-text>
					<ion-card-title>
						<ion-list class="radio-container">
							<ion-item class="input-item-custom" lines="none">
								<ion-skeleton-text class="message-text" animated style="width: 20px; height: 20px; border-radius: 50%"></ion-skeleton-text>
								<ion-skeleton-text class="message-text" animated style="width: 50px; height: 20px;"></ion-skeleton-text>
							</ion-item>
							<ion-item class="input-item-custom" lines="none">
								<ion-skeleton-text class="message-text" animated style="width: 20px; height: 20px; border-radius: 50%"></ion-skeleton-text>
								<ion-skeleton-text class="message-text" animated style="width: 50px; height: 20px;"></ion-skeleton-text>
							</ion-item>
						</ion-list>
					</ion-card-title>
				</ion-card-header>
			</ion-card>
			<ion-card class="content-container-card"> 
				<ion-card-header>
					<ion-skeleton-text class="message-text" animated style="width: 80%; height: 20px;"></ion-skeleton-text>
					<ion-skeleton-text class="message-text" animated style="width: 60%; margin-top: 10px;"></ion-skeleton-text>
					<ion-card-title>
						<ion-list class="radio-container">
							<ion-item class="input-item-custom" lines="none">
								<ion-skeleton-text class="message-text" animated style="width: 20px; height: 20px; border-radius: 50%"></ion-skeleton-text>
								<ion-skeleton-text class="message-text" animated style="width: 50px; height: 20px;"></ion-skeleton-text>
							</ion-item>
							<ion-item class="input-item-custom" lines="none">
								<ion-skeleton-text class="message-text" animated style="width: 20px; height: 20px; border-radius: 50%"></ion-skeleton-text>
								<ion-skeleton-text class="message-text" animated style="width: 50px; height: 20px;"></ion-skeleton-text>
							</ion-item>
						</ion-list>
					</ion-card-title>
				</ion-card-header>
			</ion-card>
			<ion-card class="content-container-card">
				<ion-card-header>
					<ion-skeleton-text class="message-text" animated style="width: 80%; height: 20px;"></ion-skeleton-text>
					<ion-skeleton-text class="message-text" animated style="width: 60%; margin-top: 10px;"></ion-skeleton-text>
					<ion-card-title>
						<ion-list class="radio-container">
							<ion-item class="input-item-custom" lines="none">
								<ion-skeleton-text class="message-text" animated style="width: 20px; height: 20px; border-radius: 50%"></ion-skeleton-text>
								<ion-skeleton-text class="message-text" animated style="width: 50px; height: 20px;"></ion-skeleton-text>
							</ion-item>
							<ion-item class="input-item-custom" lines="none">
								<ion-skeleton-text class="message-text" animated style="width: 20px; height: 20px; border-radius: 50%"></ion-skeleton-text>
								<ion-skeleton-text class="message-text" animated style="width: 50px; height: 20px;"></ion-skeleton-text>
							</ion-item>
						</ion-list>
					</ion-card-title>
				</ion-card-header>
			</ion-card>
			<ion-card class="content-container-card">
				<ion-card-header>
					<ion-skeleton-text class="message-text" animated style="width: 80%; height: 20px;"></ion-skeleton-text>
					<ion-skeleton-text class="message-text" animated style="width: 60%; margin-top: 10px;"></ion-skeleton-text>
					<ion-card-title>
						<ion-list class="radio-container">
							<ion-item class="input-item-custom" lines="none">
								<ion-skeleton-text class="message-text" animated style="width: 20px; height: 20px; border-radius: 50%"></ion-skeleton-text>
								<ion-skeleton-text class="message-text" animated style="width: 50px; height: 20px;"></ion-skeleton-text>
							</ion-item>
							<ion-item class="input-item-custom" lines="none">
								<ion-skeleton-text class="message-text" animated style="width: 20px; height: 20px; border-radius: 50%"></ion-skeleton-text>
								<ion-skeleton-text class="message-text" animated style="width: 50px; height: 20px;"></ion-skeleton-text>
							</ion-item>
						</ion-list>
					</ion-card-title>
				</ion-card-header>
			</ion-card>
			<ion-card class="content-container-card">
				<ion-card-header>
					<ion-skeleton-text class="message-text" animated style="width: 80%; height: 20px;"></ion-skeleton-text>
					<ion-skeleton-text class="message-text" animated style="width: 60%; margin-top: 10px;"></ion-skeleton-text>
					<ion-card-title>
						<ion-list class="radio-container">
							<ion-item class="input-item-custom" lines="none">
								<ion-skeleton-text class="message-text" animated style="width: 20px; height: 20px; border-radius: 50%"></ion-skeleton-text>
								<ion-skeleton-text class="message-text" animated style="width: 50px; height: 20px;"></ion-skeleton-text>
							</ion-item>
							<ion-item class="input-item-custom" lines="none">
								<ion-skeleton-text class="message-text" animated style="width: 20px; height: 20px; border-radius: 50%"></ion-skeleton-text>
								<ion-skeleton-text class="message-text" animated style="width: 50px; height: 20px;"></ion-skeleton-text>
							</ion-item>
						</ion-list>
					</ion-card-title>
				</ion-card-header>
			</ion-card>
		</div>
		<div class="button-container">
			<ion-button class="button-cancel" color="danger" @click="cancelEntry">Back</ion-button>
		</div>
	</ion-content>
</template>
<script>
import { format } from 'date-fns'
import { mapGetters } from 'vuex';
import { IonSkeletonText, alertController, toastController, loadingController, IonButton, IonCol, IonRow, IonIcon, IonCardContent, IonCard, IonCardSubtitle, IonCardHeader, IonLabel, IonDatetime, IonItem, IonCardTitle, IonList, IonRadioGroup, IonInput, IonContent
} from '@ionic/vue'

export default {
	name: 'ViewFormContainer',
	props: {
    getAnsweredForm: {
			type: Object
		},
		isPageLoading: {
			type: Boolean
		}
  },
	data() {
		return {
			maxDate: '',
			minDate: '',
			filledDate: '',
			hasSymptoms: '',
			hasSoreThroat: '',
			hasBodyPains: '',
			hasHeadache: '',
			hasFever: '',
			hasLossTasteSmell: '',
			hasDiarrhea: '',
			hasChestPains: '',
			hasConjunctivitis: '',
			hasConfirmedCase: '',
			hasWorkedWithConfirmedCase: '',
			hasContactWithSymptoms: '',
			hasTravelledOutsidePH: '',
			hasTravelledOutsideHome: '',
			placeVisited: '',
			answeredData: {},
			isViewMode: true,
			consent: false
		}
	},
	components: { IonSkeletonText, IonButton, IonRow, IonIcon, IonCardContent, IonCard, IonCardSubtitle, IonCardHeader, IonLabel, IonDatetime, IonItem, IonCardTitle, IonList, IonRadioGroup, IonInput, IonCol, IonContent
	},
	computed: {
		...mapGetters([
			"login/currUser",
			"login/isAuthenticated"
		]),
	},
	watch: {
		getAnsweredForm: {
			handler(val) {
				this.answeredData = val;
				this.populateAnswered(val);
			},
			deep: true
		}
	},
	created() {
		this.minDate = format(new Date('1990-01-01'), 'yyyy-MM-dd')
		this.maxDate = format(new Date(), 'yyyy-MM-dd')
		this.filledDate = format(new Date(), 'yyyy-MM-dd')
	},
	methods: {
		async cancelEntry() {
			if (this.isViewMode === true) {
				this.$router.push('/history');
			} else {
				const alert = await alertController.create({
					cssClass: 'alert-container',
					header: 'Cancel Entry',
					backdropDismiss: false,
					message: 'Are you sure you want to cancel the entry? Any unsaved changes will be lost.',
					buttons: [
						{
							text: 'No',
							role: 'cancel',
							cssClass: 'secondary',
							handler: () => {
							},
						},
						{
							text: 'Yes',
							handler: () => {
								this.isViewMode = true;
								this.$router.push('/dashboard');
								localStorage.removeItem('is_consent_agreed');
							},
						},
					],
				});

				return alert.present();
			}
		},
		async openToast(msg, type) {
			const toast = await toastController
				.create({
					message: msg,
					animated: true,
					duration: 2000,
					color: type,
					cssClass: 'toast-custom',
					end: true
				})
			return toast.present();
		},
		async dismissLoading() {
			const loading = await loadingController;
			loading.dismiss()
		},
		async presentLoading() {
			const loading = await loadingController
				.create({
					cssClass: 'loading-container',
					message: 'Please wait...',
					duration: this.timeout,
				});
			await loading.present();
		},
		populateAnswered(data) {			
			//Fill out form data
			this.filledDate = data.date;
			this.hasSymptoms = data.has_symptoms;
			this.hasSoreThroat = data.has_sore_throat;
			this.hasBodyPains = data.has_body_pains;
			this.hasHeadache = data.has_headache;
			this.hasFever = data.has_fever;
			this.hasLossTasteSmell = data.has_loss_taste_smell;
			this.hasDiarrhea = data.has_diarrhea;
			this.hasChestPains = data.has_chest_pains;
			this.hasConjunctivitis = data.has_conjunctivitis;
			this.hasConfirmedCase = data.has_confirmed_case;
			this.hasWorkedWithConfirmedCase = data.has_worked_with_confirmed_case;
			this.hasContactWithSymptoms = data.has_contact_with_symptoms;
			this.hasTravelledOutsidePH = data.has_travelled_outside_ph;
			this.hasTravelledOutsideHome = data.has_travelled_outside_home;
			this.placeVisited = data.places_visited;
		},
	}
};
</script>
<style scoped>
	.title-container {
		padding: 25px 11px;
		color: #FFF;
		background: rgb(2,0,36);
		background: linear-gradient(130deg, rgba(2,0,36,1) 0%, rgba(26,39,145,1) 35%, rgba(0,224,255,1) 100%);
	}

	.title-container h1 {
		font-weight: bolder;
		font-family: "Roboto Bold";
	}

	.title-content {
		display: block;
	}

	.title-content-notice {
		font-family: "Roboto Medium";
	}

	.content-title {
		font-family: "Roboto Medium";
		font-size: 18px;
		color: #000;
	}

	.content-subtitle i {
		font-size: 14px;
	}

	.radio-button,
	.radio-icon {
		margin-right: 5px;
	}

	.radio-container {
		display: flex;
		flex-direction: row;
	}

	.button-container {
		padding: 0 11px;
		padding-bottom: 11px;
		text-align: right;
	}

	.feeling-container {
		padding: 0 11px;
	}

	.feeling-buttons {
		height: 250px;
	}

	.feeling-title {
		font-family: "Roboto Bold";
	}

	.feeling-content {
		display: block;
	}

	.feeling-button-icon {
		width: auto;
		margin: 0 auto;
		height: 150px;
	}

	.message-title-container {
		display: flex;
		flex-direction: row;
	}

	.message-icon {
		margin-right: 5px;
	}

	.message-text {
		margin: 2px;
		font-size: 18px;
	}

	.card-action-button { 
		text-align: left;
	}

	.card-action-button-change {
		margin: 0;
		width: 100%;
	}

	.button-confirm {
		margin-left: 10px;
	}

	.input-item-custom::part(native) {
		padding-left: 0;
	}

	.mainform-input {
		border-bottom: 1px solid black;
		padding-left: 0 !important;
		padding-right: 0 !important;
	}

	.title-action-button {
		text-align: left;
		margin-top: 20px;
	}

	.title-action-button-toggle {
		position: relative;
		left: -10px;
	}

	.content-item-container {
		display: block
	}

	@media (min-width: 768px) {
		.content-container-card,
		.button-container,
		.title-content,
		.feeling-container {
			width: 700px;
			margin: 25px auto;
		}

		.button-container {
			padding-right: 0;
		}

		.feeling-content {
			display: flex;
		}

		.message-text {
			font-size: 24px;
		}

		.title-content {
			display: flex;
		}
		
		.title-content-name {
			font-size: 26px;
		}

		.content-card-data {
			font-size: 20px;
		}

		.content-item-container {
			display: flex
		}

		.card-action-button { 
			text-align: right;
		}

		.content-title {
			font-size: 20px;
		}

		.card-action-button-change {
			width: initial;
		}
		
		.title-action-button {
			text-align: right;
		}

		.title-action-button-toggle {
			position: relative;
			left: 0;
		}
	}
</style>